export const Disclaimer = () => {
  return (
    <>
      <div>
        <h1>Disclaimer & cookies</h1>
        <p>
          Oefensoftware Semsom is een product van Uitgeverij Zwijsen B.V.,
          Tilburg.
        </p>
      </div>
      <div>
        <h1>Disclaimer</h1>
        <h2>Zwijsen</h2>
        <p>
          Zwijsen is de handelsnaam van Uitgeverij Zwijsen B.V., statutair
          gevestigd aan de Locomotiefboulevard 101, 5041 SE Tilburg en met
          KvK-nummer 18030400. Onze klantenservice is bereikbaar op 013 583 88
          88.{" "}
        </p>
        <h2>Inhoud online applicaties</h2>
        <p>
          De online applicaties worden met de grootste zorgvuldigheid
          samengesteld. Dat betekent echter niet dat de online applicaties geen
          fouten zouden kunnen bevatten. Aan de inhoud van de online applicaties
          kunnen geen rechten worden ontleend.
        </p>
        <h2>Intellectuele eigendomsrechten</h2>
        <p>
          Zwijsen behoudt alle intellectuele eigendomsrechten (waaronder
          auteursrechten, merk- en modelrechten en octrooien) met betrekking tot
          alle op of via de online applicaties aangeboden content. Het is niet
          toegestaan content van de online applicaties te kopiëren, te
          downloaden of op enigerlei andere wijze openbaar te maken, te
          verspreiden of te verveelvoudigen (dit is inclusief tekst- en
          datamining, AI-training en soortgelijke technologieën) zonder onze
          voorafgaande schriftelijke toestemming. Uitzondering op deze regel
          vormen expliciete afspraken gemaakt in geval van een
          licentieovereenkomst met betrekking tot een online applicatie. Voor
          meer informatie over auteursrecht in het onderwijs verwijzen wij je
          graag naar{" "}
          <a
            href="https://www.onderwijsenauteursrecht.nl"
            target="_blank"
            rel="noreferrer"
          >
            www.onderwijsenauteursrecht.nl
          </a>
          .
        </p>
        <h2>Privacy</h2>
        <p>
          Zwijsen is, als educatieve uitgeverij die digitale leermiddelen
          aanbiedt, zich ervan bewust dat minderjarige leerlingen en jongeren
          een kwetsbare groep vormen, ook op het punt van bescherming van hun
          persoonsgegevens. Zwijsen maakt afspraken over privacy binnen de
          brancheorganisatie Media en Educatie voor Vak en Wetenschap (MEVW) en
          heeft het Convenant Digitale Onderwijsmiddelen en Privacy ondertekend.
          Meer informatie vind je in ons{" "}
          <a
            href="https://www.zwijsen.nl/privacy-statement/"
            target="_blank"
            rel="noreferrer"
          >
            privacy-statement
          </a>
          .
        </p>
        <h2>Algemene voorwaarden</h2>
        <p>
          Op het gebruik van Oefensoftware Semsom zijn de{" "}
          <a
            href="https://www.zwijsen.nl/algemene-voorwaarden/"
            target="_blank"
            rel="noreferrer"
          >
            Algemene voorwaarden Uitgeverij Zwijsen B.V.
          </a>{" "}
          van toepassing.
        </p>
      </div>
      <div>
        <h1>Cookies</h1>
        <p>
          Op deze pagina vind je meer informatie over het gebruik van cookies
          binnen de online applicaties van Zwijsen.
        </p>
        <p>
          <i>Wat zijn cookies?</i>

          <p>
            Cookies zijn kleine tekstbestanden die jouw voorkeuren tijdens het
            surfen onthouden en opslaan op je eigen computer.
          </p>
        </p>
        <p>
          <i>Wat doen cookies?</i>
          <p>
            Cookies hebben twee functies. In de eerste plaats helpen ze je bij
            het surfen door bijvoorbeeld inlognamen en instellingen te
            onthouden. Daarnaast zijn er cookies die Zwijsen informatie geven
            over het gebruik van onze online applicaties en hoe we deze kunnen
            verbeteren. Hieronder lees je meer over cookies die gebruikt worden
            door en via onze online applicaties en voor welke doeleinden.
          </p>
        </p>
        <p>
          <i>Weten cookies wie ik ben?</i>

          <p>
            Nee! Je naam, adres, leeftijd en andere persoonlijke gegevens weet
            een cookie niet. Cookies zorgen dat de website goed werkt en
            verzamelen data voor het verbeteren van het product.
          </p>
        </p>
        <p>
          <i>Welke cookies zijn er?</i>

          <p>
            <u>Noodzakelijke cookies</u>: Deze cookies helpen de online
            applicatie bruikbaarder te maken, door toegang tot de online
            applicatie mogelijk te maken en door paginanavigatie. Zonder deze
            cookies zouden de online applicaties niet naar behoren werken.
          </p>
          <p>
            <u>Voorkeurscookies</u>: Deze cookies zorgen ervoor dat een online
            applicatie informatie kan onthouden die van invloed is op het gedrag
            en de vormgeving van de applicatie.
          </p>
          <p>
            <u>Statistische cookies</u>: Deze cookies helpen Zwijsen begrijpen
            hoe bezoekers de online applicatie gebruiken, door anoniem gegevens
            te verzamelen en te rapporteren.
          </p>
        </p>
        <p>
          <i>Overzicht gebruikte cookies</i>

          <p>Op deze site gebruiken wij de volgende cookies:</p>
          <ul>
            <li>
              <i>Sessiecookie</i>: houdt gedurende de sessie bij in welke sessie
              de gebruiker actief is, daarna wordt deze cookie verwijderd
              (noodzakelijk).
            </li>
            <li>
              <i>Analytics-cookie</i>: verzamelt informatie ten behoeve van
              analytics, deze cookie blijft staan (statistisch).
            </li>
          </ul>
        </p>
        <p>
          <h2>Afmelden cookies</h2>
          <p>
            Hieronder kun je per internetbrowser vinden hoe je cookies uit kunt
            zetten.
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=nl"
                target="_blank"
                rel="noreferrer"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/nl/kb/verbeterde-bescherming-tegen-volgen-firefox-desktop"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/nl-nl/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noreferrer"
              >
                Safari
              </a>
            </li>
            <li>
              <a
                // eslint-disable-next-line max-len
                href="https://support.microsoft.com/nl-nl/microsoft-edge/browsergeschiedenis-weergeven-en-verwijderen-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4"
                target="_blank"
                rel="noreferrer"
              >
                Edge
              </a>
            </li>
          </ul>
        </p>
        <p>
          <i>Wat gebeurt er als ik de cookies uitzet?</i>

          <p>
            Wees ervan bewust dat als je geen cookies wilt, Zwijsen niet meer
            kan garanderen dat onze website helemaal goed werkt.
          </p>
        </p>
      </div>
      <div>
        <h1>Tot slot</h1>
        <p>
          Zwijsen zal deze disclaimer af en toe aan moeten passen, omdat
          bijvoorbeeld de cookies wijzigen. Zwijsen mag de inhoud van deze
          disclaimer en de cookies die opgenomen staan zonder waarschuwing
          vooraf wijzigen.
        </p>
        <p>
          Mocht je nog vragen en/of opmerkingen hebben, neem dan contact op via
          het{" "}
          <a
            href="https://www.zwijsen.nl/contactformulier/"
            target="_blank"
            rel="noreferrer"
          >
            contactformulier
          </a>
          .
        </p>
        <p>
          Op het gebruik van deze online applicatie is Nederlands recht van
          toepassing. De bevoegde Nederlandse rechter in het arrondissement
          Amsterdam is bij uitsluiting bevoegd te oordelen over eventuele
          geschillen die in verband met (het gebruik van) de online applicaties
          kunnen ontstaan en die niet in onderling overleg kunnen worden
          opgelost.
        </p>
        <p>
          <i>Laatst gewijzigd: 24 december 2024</i>
        </p>
      </div>
    </>
  );
};
