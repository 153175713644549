import { GameType } from "./game-container/game-container";

export const levelURLNames = [
  GameType.Power.toLocaleLowerCase(),
  GameType.SpeedOne.toLocaleLowerCase(),
  GameType.SpeedTwo.toLocaleLowerCase(),
];

export const levelYearFourURLNames = [
  GameType.PowerWater.toLocaleLowerCase(),
  GameType.SpeedOneAir.toLocaleLowerCase(),
  GameType.SpeedTwoLand.toLocaleLowerCase(),
];